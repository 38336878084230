import { useCallback, useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import VideoCard from '../../../Atoms/VideoCard/CourseVideoCard';
import ShimmerVideoCard from '../../../Atoms/Shimmer/VideoCard';

import Dropdown from '../../../Atoms/Dropdown';

import ProgressBar from '../../../Atoms/ProgressBar';

import Separator from '../../../Atoms/Separator';
import {
StyledProgressBar,
  Container,
  Heading,
  Content,
  FilterContainer,
  VideosScrollContainer,
  ShimmerMovieBanner,
  ShimmerMovieTitle,
  ButtonLive,
} from './styles';
import * as S from './styles';
import { CourseSideMenuProps, LiveAsCourse } from './types';
import { IoVideocamOutline } from 'react-icons/io5';
import apiV2 from '../../../../services/apiV2';
import { useAuth } from '../../../../hooks/auth';

const CourseSideMenu = ({
  setShowCertificate,
  courseDetails,
  courseSeasonOptions = [{ key: '', value: '' }],
  filters,
  firstFilter = { key: '', value: '' },
  firstItem = { key: '', value: '' },
  isLoading = false,
  isLoadingInfo = false,
  selectedPosition = 0,
  videos = [],
  onSeasonChange = () => console.log('default'),
  onVideoChange = () => console.log('default'),
  onFilterChage,
}: CourseSideMenuProps) => {
  const { push } = useHistory();
  const { user } = useAuth();
  const [livesAsCourse, setLivesAsCourse] = useState<LiveAsCourse[]>([]);
  const [livesAsCourseLoading, setLivesAsCourseLoading] = useState(true);

  const getLivesCourses = useCallback(async () => {
    const { data } = await apiV2.get(
      `/course/season/movie?courseid=${courseDetails?.courseid}&seasonid=AoVivo&userid=${user?.userid}`,
    );

    if (data) {
      setLivesAsCourseLoading(false);
      setLivesAsCourse(data);
    }
  }, [courseDetails, user?.userid]);

  useEffect(() => {
    if (courseDetails && courseDetails?.is_live) {
      getLivesCourses();
    }
  }, [courseDetails, getLivesCourses]);

  return (
    <Container customType="">
      <Heading>
        <>
          {courseDetails && courseDetails.thumburl ? (
            <>
              <h3>
                {' '}
                <FiArrowLeft size={24} onClick={() => push('/courses')} />
                {courseDetails.title}
              </h3>
              <img src={courseDetails.thumburl} alt="thumb" />
            </>
          ) : (
            <ShimmerMovieBanner />
          )}
{/*           {courseDetails ? <p>{courseDetails?.description}</p> : <ShimmerMovieTitle />} */}

          {courseDetails && courseDetails.userprogress >= 0.96 && courseDetails.certificate && (
            <ButtonLive className="certificate" type="button" onClick={() => setShowCertificate(true)}>
              Certificado
            </ButtonLive>
          )}

{courseDetails && courseDetails.userprogress < 0.96 && courseDetails.certificate && (




          <StyledProgressBar>

              <p className="progresso">
                {'Progresso: (' + (courseDetails.userprogress*100).toFixed(2) + '%)'}
              </p>
            <ProgressBar at={courseDetails.userprogress*100} customHeight={10} />
          </StyledProgressBar>
           )}
{/*           <Separator customWidth={70} customColor="#ffd35c" type="horizontal" /> */}

          {courseDetails && courseDetails.is_live && (
            <Heading>
              <S.HeadingScrollView>
                {livesAsCourse?.length > 0 && !livesAsCourseLoading ? (
                  livesAsCourse.map((item, key) => (
                    <S.ContentHeading key={key + item.courseid_seasonid_movieid}>
                      <S.ContentTextHeading>
                        <S.WrappetTextTitle>
                          <p>{key + 1}.</p>
                          <h4>{item.title}</h4>
                        </S.WrappetTextTitle>
                        <S.ContentTextStyled>{item.description}</S.ContentTextStyled>
                      </S.ContentTextHeading>
                      <S.LinkButton href={item.url} target="_blank">
                        <S.ButtonLive>
                          live
                          <IoVideocamOutline className="iconLive" />
                        </S.ButtonLive>
                      </S.LinkButton>
                    </S.ContentHeading>
                  ))
                ) : (
                  <S.DescriptionLive />
                )}
              </S.HeadingScrollView>
            </Heading>
          )}
        </>
      </Heading>
      <Content>
        <FilterContainer>
          <>
{/*             <p>Escolha</p> */}
            <div className="subject-select">
              <Dropdown
                arrowColor="#ffd35c"
                textColor="#ffd35c"
                title="Selecionar aula"
                items={courseSeasonOptions}
                defaultValue={isLoading ? { key: '', value: '' } : firstItem}
                isLoading={videos.length < 1}
                onChange={onSeasonChange}
                customFontSize={21}
                size="small"
              />
            </div>
            {filters && filters[0] && filters[0] !== '' && filters[0] !== ' ' && (
              <div className="class-select">
                <Dropdown
                  title="Selecione uma data"
                  arrowColor="#ffff"
                  textColor="#ffff"
                  backgroundCollor="#171a21"
                  customRadius={30}
                  customHeight={30}
                  customFontSize={11}
                  items={
                    filters
                      ? filters.map(filter => ({
                          key: filter,
                          value: filter,
                        }))
                      : [{ key: '1', value: '1' }]
                  }
                  defaultValue={firstFilter}
                  isLoading={false}
                  size="small"
                  onChange={item => onFilterChage(item)}
                />
              </div>
            )}
          </>
        </FilterContainer>
        <VideosScrollContainer className="hasVerticalScroll">
          {videos.length > 0 &&
            videos.map((video, index) => (
              <VideoCard
                key={video.movieid}
                index={index + 1}
                video={video}
                isWatching={video.position === selectedPosition}
                onSelect={onVideoChange}
                selectedPosition={selectedPosition}
                exercisePreviewActive={video.exerciseshortmessage !== ' '}
                onOpen={() => {}}
              />
            ))}
          {isLoading && videos.length < 1 && (
            <>
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
              <ShimmerVideoCard type="small" />
            </>
          )}
        </VideosScrollContainer>
      </Content>
    </Container>
  );
};

export default CourseSideMenu;
